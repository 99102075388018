export const informationYouProvide = {
  title: 'Information You Provide',
  list: [
    {
      bold: 'Your Account Information.',
      text:
        ' You provide your mobile phone number and basic information (including a profile name) to create a SoPlan account. You provide us, all in accordance with applicable laws, the phone numbers of invited contacts, including those of both the users of our Services and your other contacts. You may provide us an email address. You may also add other information to your account, such as a profile picture and about information.'
    },
    {
      bold: 'Your Events.',
      text:
        ' We retain your events on our servers and we store them securely. Events are transmitted using an encrypted communication channel. We do encourage you to use a secure network instead of a public network.'
    },
    {
      bold: 'Your Connections.',
      text:
        ' To help you organize how you meet with others, we may help you identify your contacts who also use SoPlan and the frequency you meet. You may be added to groups but you are in control of who can invite you to groups.'
    },
    {
      bold: 'Customer Support.',
      text:
        ' You may provide us with information related to your use of our Services, including copies of your events, and how to contact you so we can provide you customer support. For example, you may send us an email with information relating to our app performance or other issues.'
    }
  ]
}

export const thirdPartyInformation = {
  title: 'Third-Party Information',
  list: [
    {
      bold: 'Information Others Provide About You.',
      text:
        ' We receive information about you from other users and businesses. For example, when other users or businesses you know use our Services, they may provide your phone number, name, and other information (like information from their mobile address book or in the case of businesses, additional information about you such as unique identifiers), just as you may provide theirs, or they may invite you to an event and invite to a group. We require each of these users and businesses to have lawful rights to collect, use, and share your information before providing any information to us.'
    },
    {
      bold: 'Businesses On SoPlan.',
      text:
        ' Businesses you interact with using SoPlan provide us information about their interactions with you. A business on SoPlan may also use another company to assist it managing events on behalf of and in support of that business. Please note that when businesses use third-party services, their own terms and privacy policies will govern your use of those services and their use of your information on those services.'
    },
    {
      bold: 'Third-Party Service Providers.',
      text:
        ' We work with third-party service providers to help us operate, provide, improve, understand, customize, support, and market our Services. For example, we work with companies to distribute our apps, provide our infrastructure, delivery, and other systems, supply location, map, and places information, process payments, help us understand how people use our Services, market our Services, help you connect with businesses using our Services, conduct surveys and research for us, and help with customer service. These companies may provide us information about you in certain circumstances; for example, app stores may provide us reports to help us diagnose and fix service issues.'
    }
  ]
}

export const automaticallyCollectedInformation = {
  title: 'Automatically Collected Information',
  list: [
    {
      bold: 'Usage And Log Information.',
      text:
        'We collect information about your activity on our Services, like service-related, diagnostic, and performance information. This includes information about your activity (including how you use our Services, your Services settings, how you interact with others using our Services, and the time, frequency, and duration of your activities and interactions), log files, and diagnostic, crash, website, and performance logs and reports. This also includes information about when you registered to use our Services, the features you use and how often you meet with other users.'
    },
    {
      bold: 'Device And Connection Information.',
      text:
        'We collect device and connection-specific information when you install, access, or use our Services. This may include information like hardware model, operating system information, battery level, signal strength, app version, browser information, and mobile network, connection information including phone number, mobile operator or ISP, language and time zone, and IP, device operations information, and identifiers like device identifiers.'
    },
    {
      bold: 'Location Information.',
      text:
        "We may collect device location information if you use our location features, like when you choose to plan an event with users, share your availability with other users and for diagnostics and troubleshooting purposes such as if you are having trouble with our app's location features. We use various technologies to determine location, including IP, GPS, Bluetooth signals, and information about nearby Wi-Fi access points, beacons, and cell towers."
    },
    {
      bold: 'Cookies.',
      text:
        'We may use cookies to operate and provide our Services, including to provide our Services that are web-based, improve your experiences, understand how our Services are being used, and customize our Services. For example, we use cookies to provide SoPlan for web and other web-based services. We may also use cookies to understand which of our FAQs are most popular and to show you relevant content related to our Services. Additionally, we may use cookies to remember your choices, like your language preferences, to provide a safer experience, and otherwise to customize our Services for you.'
    }
  ]
}

export const howWeUserInformation = {
  title: null,
  list: [
    {
      bold: 'Our Services.',
      text:
        ' We use the information we have to operate and provide our Services, including providing customer support, and improving, fixing, and customizing our Services. We understand how people use our Services and analyze and use the information we have to evaluate and improve our Services, research, develop, and test new services and features, and conduct troubleshooting activities. We also use your information to respond to you when you contact us.'
    },
    {
      bold: 'Safety And Security.',
      text:
        ' We verify accounts and activity, and promote safety and security on and off our Services, such as by investigating suspicious activity or violations of our Terms, and to ensure our Services are being used legally.'
    },

    {
      bold: 'Communications About Our Services.',
      text:
        ' We use the information we have to communicate with you about our Services and features and let you know about our terms and policies and other important updates. We may provide you marketing for our Services.'
    },
    {
      bold: 'No Third-Party Banner Ads.',
      text:
        ' We still do not allow third-party banner ads on SoPlan. We have no intention to introduce them, but if we ever do, we will update this policy.'
    }
  ]
}

export const informationYouAndWeShare = {
  title: null,
  list: [
    {
      bold: 'Send Your Information To Those You Choose To Communicate With.',
      text:
        'You share your information as you use and communicate through our Services.'
    },
    {
      bold: 'Account Information.',
      text:
        'Your phone number, profile information and availability may be available to anyone who uses our Services, although you can configure your Services settings to manage certain information available to other users and businesses with whom you communicate.'
    },
    {
      bold: 'Your Contacts And Others.',
      text:
        'Users and businesses with whom you communicate may store or reshare your information (including your phone number or events) with others on and off our Services. We may add features so you can manage the users of our Services with whom you communicate and certain information you share.'
    },
    {
      bold: 'Third-Party Service Providers.',
      text:
        'We work with third-party service providers to help us operate, provide, improve, understand, customize, support, and market our Services. '
    }
  ]
}

export const ourLegalBasesForProcessingInformation = {
  title: null,
  list: [
    { text: 'as necessary to fulfill our Terms;' },
    { text: 'consistent with your consent, which you can revoke at any time;' },
    { text: 'as necessary to comply with our legal obligations;' },
    {
      text: 'occasionally to protect your vital interests, or those of others;'
    },
    { text: 'as necessary in the public interest;' },
    {
      text:
        "as necessary for our (or others') legitimate interests, including our interests in providing an innovative, relevant, safe, and profitable service to our users and partners, unless those interests are overridden by your interests or fundamental rights and freedoms that require protection of personal data."
    }
  ]
}

export const managingAndDeletingYourInformation = {
  title: null,
  list: [
    {
      bold: 'Services Settings.',
      text:
        ' You can change your Services settings to manage certain information available to other users. '
    },
    {
      bold:
        'Changing Your Mobile Phone Number, Profile Name And Picture, And About Information.',
      text:
        ' You must change your mobile phone number using our in-app change number feature and transfer your account to your new mobile phone number (if feature is available). You can also change your profile name, profile picture, and about information at any time.'
    },
    {
      bold: 'Deleting Your Account.',
      text:
        ' You may delete your account at any time (including if you want to revoke your consent to our use of your information) using our in-app delete my account feature. When you delete your account, your information will be deleted from our servers as well as any of your other information we no longer need to operate and provide our Services. Be mindful that if you only delete our Services from your device without using our in-app delete my account feature, your information may be stored with us for a longer period. Please remember that when you delete your account, it does not affect the information other users have relating to you, such as their copy of the events you sent them.'
    }
  ]
}
