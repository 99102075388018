import React, { useState, useRef } from 'react'
// axios
import axios from 'axios'
// toastify
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import 'styles/Toastify.css'
// react-google-recaptch
import ReCAPTCHA from 'react-google-recaptcha'
// constants
import { SUBSCRIBE_POST_API_URL } from 'constants/api'
// helpers
import { validate } from 'helpers/validation'
// components
import Breadcrumbs from 'components/Breadcrumbs'
import SubmitButton from 'components/SubmitButton'
import Alert from 'components/Alert'
import Input from 'components/Input'
// css
import classes from 'pages/Business/Business.module.css'

const Business = () => {
  const [email, setEmail] = useState('')
  const [token, setToken] = useState('')
  const captchaRef = useRef(null)

  const handleReCaptcha = (value) => setToken(value)

  const handleClick = () => {
    if (!validate(email) || !token) {
      toast.dark(
        () => (
          <Alert>
            <p className={classes.bold}>
              Enter a valid E-mail address or/and confirm that you are not a
              robot. For example:
            </p>
            <p>someone@example.com</p>
          </Alert>
        ),
        {
          toastId: 'validation'
        }
      )
    } else {
      axios
        .post(SUBSCRIBE_POST_API_URL, { email, token })
        .then(() => {
          setEmail('')
          captchaRef.current.reset()

          toast.dark(
            () => (
              <Alert>
                <p className={classes.bold}>Success</p>
              </Alert>
            ),
            {
              toastId: 'success'
            }
          )
        })
        .catch(() => {
          toast.dark(
            () => (
              <Alert>
                <p className={classes.bold}>Something went wrong</p>
              </Alert>
            ),
            {
              toastId: 'error'
            }
          )
        })
    }
  }

  const handleChangeEmail = (e) => setEmail(e.target.value)

  return (
    <main className="page">
      <div className={classes.container}>
        <div className={classes.breadcrumbsContainer}>
          <Breadcrumbs
            data={[{ title: 'For Businesses', href: '/business' }]}
          />
        </div>
        <div className={classes.firstScreen}>
          <div className={classes.info}>
            <h2 className={classes.title}>Save time and effort!</h2>

            <h3 className={classes.subTitle}>
              share your availability with customers and let customers pick a time
            </h3>

            <p className={classes.bold}>
              SoPlan is a scheduling platform which eliminates the back-and-forth communication when scheduling an event with customers
            </p>

            <p>
              With SoPlan you can easily connect your calendar, specify your availability 
              and share this with your customers. 
              Your customers can overlay their own calendar and automatically identify which times are suitable for both parties. 
              Your customers no longer need to switch between calendars anymore. 

            </p>

            <p>
            Stay in control of your calendar! You can define multiple availability schedules.
            For example you can have one rule which allows customers to schedule meetings with a duration of 15 minutes and which is only available on specific days. Or you have an one time event such as a webinar which allows multiple attendees.


            </p>

            <p>
              And another benefit is customers no longer forget their appointment as automated reminders are sent. 
            </p>

            <p>With SoPlan, planning is made simple.</p>
          </div>

          <div className={classes.img} />
        </div>
        <div className={classes.formContainer}>
          <h3 className={classes.formTitle}>
            Be our launching customer and we reward your loyalty!¹
          </h3>
          <form className={classes.form}>
            <Input
              value={email}
              className={classes.input}
              onChange={handleChangeEmail}
              placeholder="Enter your e-mail"
              name="email"
              type="text"
            />

            <SubmitButton className={classes.button} onClick={handleClick}>
              Subscribe
            </SubmitButton>

            <ReCAPTCHA
              ref={captchaRef}
              className={classes.reCaptcha}
              onChange={handleReCaptcha}
              sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
            />
            <p className={classes.note}>
              1. You will be rewarded with a lifetime discount on our monthly subscription plan. You get early access and have an influence on new features.
            </p>
          </form>
        </div>

        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
      </div>
    </main>
  )
}

export default Business
