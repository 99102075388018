import React from 'react'
// components
import Breadcrumbs from 'components/Breadcrumbs'
// css
import classes from 'pages/PrivacyPolicy/PrivacyPolicy.module.css'
import { otherSubList, ourServices } from 'constants/termsOfService'
import SubList from 'components/SubList'

const TermsOfService = () => {
  return (
    <main className="page">
      <div className={classes.container}>
        <Breadcrumbs
          data={[
            {
              title: 'Terms of Service',
              href: '/terms-of-service'
            }
          ]}
        />

        <nav>
          <ol className={classes.navList}>
            <li>
              <a href="#our-services">Our services</a>
            </li>
            <li>
              <a href="#about-our-services">About our services</a>
            </li>
            <li>
              <a href="#acceptable-use-of-our-services">
                Privacy Policy And User Data
              </a>
            </li>
            <li>
              <a href="#acceptable-use-of-our-services">
                Acceptable use of our services
              </a>
            </li>
            <li>
              <a href="#licenses">Licenses</a>
            </li>
            <li>
              <a href="#disclaimers">Disclaimer</a>
            </li>
            <li>
              <a href="#limitation-of-liability">Limitation of liability</a>
            </li>
            <li>
              <a href="#indemnification">Indemnification</a>
            </li>
            <li>
              <a href="#dispute-resolution">Dispute resolution</a>
            </li>
            <li>
              <a href="#availability-and-termination-of-our-services">
                Availability and termination of our services
              </a>
            </li>
            <li>
              <a href="#other">Other</a>
            </li>
          </ol>
        </nav>

        <ul className={classes.infoList}>
          <li id="our-services">
            <h3 className={classes.itemTitle}>1. Our Services</h3>
            <p className={classes.itemParagraph}>
              In order to provide our Services (as defined below) through our
              apps, services, features, software, or website, we need to obtain
              your express agreement to our Terms of Service ("Terms"). You
              agree to our Terms by registering, installing, accessing, or using
              our apps, services, features, software, or website.
            </p>

            <SubList data={ourServices} />
          </li>

          <li id="about-our-services">
            <h3 className={classes.itemTitle}>2. About Our Services</h3>

            <p className={classes.itemParagraph}>
              <span className={classes.bold}>Registration. </span>
              You must register for our Services using accurate information,
              provide your current mobile phone number, and, if you change it,
              update your mobile phone number using our in-app change number
              feature if available. You agree to receive text messages from us or our
              third-party providers with verification codes to register for our Services.
            </p>

            <p className={classes.itemParagraph}>
              <span className={classes.bold}>Address Book. </span>
              You provide us, all in accordance with applicable laws, the phone
              numbers of contacts you invite to events including for both the users of
              our Services and your other contacts.
            </p>

            <p className={classes.itemParagraph}>
              <span className={classes.bold}>Age. </span>
              You must be at least 16 years old to use our Services. If you are
              not old enough to have authority to agree to our Terms, your
              parent or guardian must agree to our Terms on your behalf.
            </p>

            <p className={classes.itemParagraph}>
              <span className={classes.bold}>Devices And Software. </span>
              You must provide certain devices, software, and data connections
              to use our Services, which we otherwise do not supply. In order to
              use our Services, you consent to manually or automatically
              download and install updates to our Services. You also consent to
              our sending you notifications via SoPlan from time to time, as
              necessary to provide our Services to you.
            </p>

            <p className={classes.itemParagraph}>
              <span className={classes.bold}>Fees And Taxes. </span>
              You are responsible for all carrier data plans, Internet fees, and
              other fees and taxes associated with your use of our Services.
            </p>
          </li>

          <li id="privacy-policy-and-user-data">
            <h3 className={classes.itemTitle}>
              3. Privacy Policy And User Data
            </h3>
            <p className={classes.itemParagraph}>
              SoPlan’s Privacy Policy describes the types of information we
              receive and collect from you, how we use and share this
              information, and your rights in relation to the processing of
              information about you.
            </p>
          </li>

          <li id="acceptable-use-of-our-services">
            <h3 className={classes.itemTitle}>
              4. Acceptable Use Of Our Services
            </h3>

            <p className={classes.itemParagraph}>
              <span className={classes.bold}>Our Terms And Policies. </span>
              You must use our Services according to our Terms and policies. If
              you violate our Terms or policies, we may take action with respect
              to your account, including disabling or suspending your account
              and, if we do, you must not create another account without our
              permission.
            </p>

            <p className={classes.itemParagraph}>
              <span className={classes.bold}>Legal And Acceptable Use. </span>
              You must access and use our Services only for legal, authorized,
              and acceptable purposes. You will not use (or assist others in
              using) our Services in ways that: (a) violate, misappropriate, or
              infringe the rights of SoPlan, our users, or others, including
              privacy, publicity, intellectual property, or other proprietary
              rights; (b) are illegal, obscene, defamatory, threatening,
              intimidating, harassing, hateful, racially or ethnically
              offensive, or instigate or encourage conduct that would be illegal
              or otherwise inappropriate, including promoting violent crimes;
              (c) involve publishing falsehoods, misrepresentations, or
              misleading statements; (d) impersonate someone; (e) involve
              sending illegal or impermissible events, such as bulk events,
              automated tools, and the like; or (f) involve any non-personal use
              of our Services unless otherwise authorized by us.
            </p>

            <p className={classes.itemParagraph}>
              <span className={classes.bold}>
                Harm To SoPlan Or Our Users.{' '}
              </span>
              You must not (or assist others to) directly, indirectly, through
              automated or other means access, use, copy, adapt, modify, prepare
              derivative works based upon, distribute, license, sublicense,
              transfer, display, perform, or otherwise exploit our Services in
              impermissible or unauthorized manners, or in ways that burden,
              impair, or harm us, our Services, systems, our users, or others,
              including that you must not directly or through automated means:
              (a) reverse engineer, alter, modify, create derivative works from,
              decompile, or extract code from our Services; (b) send, store, or
              transmit viruses or other harmful computer code through or onto
              our Services; (c) gain or attempt to gain unauthorized access to
              our Services or systems; (d) interfere with or disrupt the safety,
              security, or performance of our Services; (e) create accounts for
              our Services through unauthorized or automated means; (f) collect
              the information of or about our users in any impermissible or
              unauthorized manner; (g) sell, resell, rent, or charge for our
              Services in an unauthorized manner; (h) distribute or make our
              Services available over a network where they could be used by
              multiple devices at the same time, except as authorized through
              tools we have expressly provided via our Services; or (i) create
              software or APIs that function substantially the same as our
              Services and offer them for use by third parties in an
              unauthorized manner.
            </p>

            <p className={classes.itemParagraph}>
              <span className={classes.bold}>
                Keeping Your Account Secure.{' '}
              </span>
              You are responsible for keeping your device and your SoPlan
              account safe and secure, and you must notify us promptly of any
              unauthorized use or security breach of your account or our
              Services.
            </p>
          </li>

          <li id="licenses">
            <h3 className={classes.itemTitle}>5. Licenses</h3>

            <p className={classes.itemParagraph}>
              <span className={classes.bold}>Your Rights. </span>
              SoPlan does not claim ownership of the information that you submit
              for your SoPlan account or through our Services. You must have the
              necessary rights to such information that you submit for your
              SoPlan account or through our Services and the right to grant the
              rights and licenses in our Terms.
            </p>

            <p className={classes.itemParagraph}>
              <span className={classes.bold}>SoPlan's Rights. </span>
              We own all copyrights, trademarks, domains, logos, trade dress,
              trade secrets, patents, and other intellectual property rights
              associated with our Services. You may not use our copyrights,
              trademarks, domains, logos, trade dress, patents, or other
              intellectual property rights unless you have our express
              permission.
            </p>
          </li>

          <li id="disclaimers">
            <h3 className={classes.itemTitle}>6. Disclaimers</h3>

            <p className={classes.itemParagraph}>
              WE WILL USE REASONABLE SKILL AND CARE IN PROVIDING OUR SERVICES TO
              YOU AND IN KEEPING IT A SAFE, SECURE, AND ERROR-FREE ENVIRONMENT,
              BUT WE DO NOT GUARANTEE THAT SOPLAN WILL ALWAYS FUNCTION WITHOUT
              DISRUPTIONS, DELAYS, OR IMPERFECTIONS. YOU USE OUR SERVICES AT
              YOUR OWN RISK AND SUBJECT TO THE FOLLOWING DISCLAIMERS. WE ARE
              PROVIDING OUR SERVICES ON AN "AS IS" BASIS WITHOUT ANY EXPRESS OR
              IMPLIED WARRANTIES, INCLUDING, BUT NOT LIMITED TO, WARRANTIES OF
              MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE,
              NON-INFRINGEMENT, AND FREEDOM FROM COMPUTER VIRUS OR OTHER HARMFUL
              CODE. WE DO NOT WARRANT THAT ANY INFORMATION PROVIDED BY US IS
              ACCURATE, COMPLETE, OR USEFUL, THAT OUR SERVICES WILL BE
              OPERATIONAL, ERROR FREE, SECURE, OR SAFE, OR THAT OUR SERVICES
              WILL FUNCTION WITHOUT DISRUPTIONS, DELAYS, OR IMPERFECTIONS. WE DO
              NOT CONTROL AND ARE NOT RESPONSIBLE FOR, CONTROLLING HOW OR WHEN
              OUR USERS USE OUR SERVICES OR THE FEATURES, SERVICES, AND
              INTERFACES OUR SERVICES PROVIDE. WE ARE NOT RESPONSIBLE FOR AND
              ARE NOT OBLIGATED TO CONTROL THE ACTIONS OR INFORMATION (INCLUDING
              CONTENT) OF OUR USERS OR OTHER THIRD PARTIES. YOU RELEASE US, OUR
              SUBSIDIARIES, AFFILIATES, AND OUR AND THEIR DIRECTORS, OFFICERS,
              EMPLOYEES, PARTNERS, AND AGENTS (TOGETHER, THE "SOPLAN PARTIES")
              FROM ANY CLAIM, COMPLAINT, CAUSE OF ACTION, OR CONTROVERSY
              (TOGETHER, "CLAIM") AND DAMAGES, KNOWN AND UNKNOWN, RELATING TO,
              ARISING OUT OF, OR IN ANY WAY CONNECTED WITH ANY SUCH CLAIM YOU
              HAVE AGAINST ANY THIRD PARTIES. YOUR RIGHTS WITH RESPECT TO SOPLAN
              ARE NOT MODIFIED BY THE FOREGOING DISCLAIMER IF THE LAWS OF YOUR
              COUNTRY OF RESIDENCE, APPLICABLE AS A RESULT OF YOUR USE OF OUR
              SERVICES, DO NOT PERMIT IT. IF YOU ARE A UNITED STATES RESIDENT,
              YOU WAIVE CALIFORNIA CIVIL CODE §1542, WHICH SAYS: A GENERAL
              RELEASE DOES NOT EXTEND TO CLAIMS WHICH THE CREDITOR DOES NOT KNOW
              OR SUSPECT TO EXIST IN HIS OR HER FAVOR AT THE TIME OF EXECUTING
              THE RELEASE, WHICH IF KNOWN BY HIM OR HER MUST HAVE MATERIALLY
              AFFECTED HIS OR HER SETTLEMENT WITH THE DEBTOR.
            </p>
          </li>

          <li id="limitation-of-liability">
            <h3 className={classes.itemTitle}>7. Limitation Of Liability</h3>
            <p className={classes.itemParagraph}>
              OUR AGGREGATE LIABILITY RELATING TO, ARISING OUT OF, OR IN ANY WAY
              IN CONNECTION WITH OUR TERMS, US, OR OUR SERVICES SHALL BE LIMITED
              TO LOSSES THAT ARE A REASONABLY FORESEEABLE CONSEQUENCE OF SUCH
              BREACH (EXCEPT IN RELATION TO DEATH, PERSONAL INJURY, OR
              FRAUDULENT MISREPRESENTATION) AND WILL NOT EXCEED THE AMOUNT YOU
              HAVE PAID US IN THE PAST TWELVE MONTHS.
            </p>
            <p className={classes.itemParagraph}>
              YOUR RIGHTS WITH RESPECT TO SOPLAN ARE NOT MODIFIED BY THE
              FOREGOING LIMITATION IF THE LAWS OF YOUR COUNTRY OF RESIDENCE,
              APPLICABLE AS A RESULT OF YOUR USE OF OUR SERVICES, DO NOT PERMIT
              IT.
            </p>
          </li>

          <li id="indemnification">
            <h3 className={classes.itemTitle}>8. Indemnification</h3>

            <p className={classes.itemParagraph}>
              If anyone brings a claim ("Third Party Claim") against us related
              to your actions, information, or content on SoPlan, you will, to
              the extent permitted by law, indemnify and hold the SoPlan Parties
              harmless from and against all liabilities, damages, losses, and
              expenses of any kind (including reasonable legal fees and costs)
              relating to, arising out of, or in any way in connection with any
              of the following: (a) your access to or use of our Services,
              including information provided in connection therewith; (b) your
              breach of our Terms or of applicable law; or (c) any
              misrepresentation made by you. You will cooperate as fully as
              required by us in the defense or settlement of any Third Party
              Claim. Your rights with respect to SoPlan are not modified by the
              foregoing indemnification if the laws of your country of
              residence, applicable as a result of your use of our Services, do
              not permit it.
            </p>
          </li>

          <li id="dispute-resolution">
            <h3 className={classes.itemTitle}>9. Dispute Resolution</h3>

            <p className={classes.itemParagraph}>
              You agree that the Claim must be resolved in a competent court in
              the Netherlands that has jurisdiction over the Claim and that the
              laws of the Netherlands will govern these Terms and any Claim,
              without regard to conflict of law provisions.
            </p>
          </li>

          <li id="availability-and-termination-of-our-services">
            <h3 className={classes.itemTitle}>
              10. Availability And Termination Of Our Services
            </h3>

            <p className={classes.itemParagraph}>
              <span className={classes.bold}>
                Availability Of Our Services.{' '}
              </span>
              We are always trying to improve our Services.That means we may add
              or remove our Services, features, functionalities, and the support
              of certain devices and platforms. Our Services may be interrupted,
              including for maintenance, repairs, upgrades, or network or
              equipment failures. We may discontinue some or all of our
              Services, including certain features and the support for certain
              devices and platforms, at any time after a notice period of 30
              days, where possible. Events beyond our control may affect our
              Services, such as events in nature and other force majeure events.
            </p>

            <p className={classes.itemParagraph}>
              <span className={classes.bold}>Termination. </span>
              Although we hope you remain a SoPlan user, you can terminate your
              relationship with SoPlan anytime for any reason by deleting your
              account.
            </p>
            <p className={classes.itemParagraph}>
              We may also modify, suspend, or terminate your access to or use of
              our Services anytime for suspicious or unlawful conduct, including
              for fraud, or if we reasonably believe you violate our Terms or
              create harm, risk, or possible legal exposure for us, our users,
              or others. The following provisions will survive any termination
              of your relationship with SoPlan: "Licenses," "Disclaimers,"
              "Limitation of Liability," "Indemnification," "Dispute
              Resolution," "Availability and Termination of our Services," and
              "Other,".
            </p>
          </li>

          <li id="other">
            <h3 className={classes.itemTitle}>11. Other</h3>
            <SubList data={otherSubList} />
          </li>
        </ul>
      </div>
    </main>
  )
}

export default TermsOfService
