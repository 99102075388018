import React from 'react'
// assets
import GPDownloadDark from 'assets/logo/google-play-download-dark.svg'
import ASDownloadDark from 'assets/logo/app-store-download-dark.svg'
// css
import classes from 'pages/Main/Main.module.css'
import DownloadButton from 'components/DownloadButton'
import { Link } from 'react-router-dom'

const Main = () => {
  return (
    <main className="page">
      <section className={classes.firstScreenSection}>
        <div className={classes.infoContainer}>
          <h1 className={classes.title}>PLANNING MADE SIMPLE.</h1>

          <p className={classes.subtitle}>
            SoPlan is a scheduling platform which eliminates the back-and-forth communication when scheduling events
          </p>

          <div className={classes.buttons}>
            <DownloadButton
              imgSrc={GPDownloadDark}
              href="https://play.google.com/store/apps/details?id=com.soplan"
              alt="Download from google play"
            />

            <DownloadButton
              imgSrc={ASDownloadDark}
              href="https://apps.apple.com/us/app/soplan/id1521063648"
              alt="Download from apple store"
            />
          </div>
        </div>

        <div className={classes.phoneContainer}>
          <div className={classes.phone} />
        </div>
      </section>

      <section className={classes.canIDoSection}>
        <div className={classes.contentContainer}>
          <h1 className={classes.title}>What can it do?</h1>
        </div>

        <div className={classes.contentContainer}>
          <p className={`${classes.paragraph} ${classes.bold}`}>
            With SoPlan, you save time and effort when scheduling events with other people
          </p>

          <p className={classes.paragraph}>
            Share availability, create events, invite others, check RSVP, automated date picker.
          </p>
        </div>

        <div className={classes.contentContainer}>
          <p className={classes.paragraph}>
            It works like a regular calendar, except you can use phone numbers to share events
          </p>

          <p className={`${classes.paragraph} ${classes.bold}`}>
            To get started, all you need to have is a mobile phone number.
            Planning made simple.
          </p>
        </div>
      </section>

      <section className={classes.featuresSection}>
        <div className={classes.firstFeature}>
          <div className={classes.phoneContainer}>
            <div className={classes.phone} />
          </div>

          <div className={classes.paragraphsContainer}>
            <p className={classes.paragraph}>
              <span className={classes.highlight}>
                See all your upcoming events
              </span>{' '}
              and get notified about invites and updated events.
            </p>
            <p className={classes.paragraph}>
              <span className={classes.highlight}>
                You can easily SEE who accepted or Declined an invitation.
              </span>
            </p>
            <p className={classes.paragraph}>
              <span className={classes.highlight}>Time changed?</span>
            </p>
            <p className={classes.paragraph}>
              No worries, just update the event and everyone gets a
              notification.
            </p>
          </div>
        </div>

        <div className={classes.secondFeature}>
          <div className={classes.paragraphsContainer}>
            <p className={classes.paragraph}>
              Why bother with e-mail addresses?
            </p>

            <p className={classes.paragraph}>
              <span className={classes.highlight}>
                mobile phone number is what
                <br /> you need to invite people.
              </span>
            </p>

            <p className={classes.paragraph}>Yes, it's really that easy.</p>
          </div>

          <div className={classes.phoneContainer}>
            <div className={classes.phone} />
          </div>
        </div>

        <div className={classes.thirdFeature}>
          <div className={classes.phoneContainer}>
            <div className={classes.phone} />
          </div>

          <div className={classes.paragraphsContainer}>
            <p className={classes.paragraph}>
              View event details and see what is updated
            </p>
            <p className={classes.paragraph}>
              <span className={classes.highlight}>
                Accept or decline the invitation so <br /> the owner can see who
                is attending to the party
              </span>
            </p>
            <p className={classes.paragraph}>
              See who else is invited and if they are attending
            </p>
          </div>
        </div>
      </section>

      {/* <section className={`${classes.canIDoSection} ${classes.forBusinesses}`}>
        <div className={classes.contentContainer}>
          <h1 className={classes.title}>For Businesses</h1>
        </div>

        <div className={classes.contentContainer}>
          <p className={`${classes.paragraph} ${classes.bold}`}>
            Our platform can be used by local businesses to send or receive
            appointments to and from customers
          </p>

          <p className={classes.paragraph}>
            A barbershop can publish their agenda and allow customers to
            schedule their own appointment. No more picking up the phone or
            waiting to be answered.
          </p>
        </div>

        <div className={classes.contentContainer}>
          <p className={classes.paragraph}>
            A small gym can allow people to register for classes and update
            their members if the class is canceled or time is changed.
          </p>

          <p className={`${classes.paragraph} ${classes.bold}`}>
            <Link className={classes.link} to="/business">
              Subscribe
            </Link>{' '}
            today and experience how cost effective SoPlan is for your business.
          </p>
        </div>
      </section> */}
{/* 
      <section className={classes.devicesPreview}>
        <div className={classes.macBook} />

        <div className={classes.iPhone} />

        <div className={classes.secondMacBook} />

        <div className={classes.modal} />
      </section> */}
    </main>
  )
}

export default Main
