/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useParams, useLocation, Redirect } from 'react-router-dom'
import makeUrl from 'add-event-to-calendar'
import ClickAwayListener from 'react-click-away-listener'
import LocalizedStrings from 'react-localization'
import axios from 'axios'
import './Invitation.css'
import './images/Roboto-Thin.ttf'
import { outlook as OutLookLink, office365 } from 'calendar-link'
import {
  SHOW_EVENT_PUBLIC_API_URL,
  UPDATE_EVENT_PUBLIC_API_URL
} from 'constants/api'
import googleStore from './images/GP.svg'
import appleStore from './images/AS.svg'
import logo2 from './images/Logo.svg'
import symbol from './images/Symbol.svg'
import accept from './images/Tick.svg'
import decline from './images/X.svg'
import NoImage from './images/download.png'
import Yes from './images/Yes.svg'
import No from './images/No.svg'
import apple from './images/icon-apple-t5.svg'
import google from './images/icon-google-t5.svg'
import outlook from './images/icon-outlookcom-t5.svg'
import yahoo from './images/icon-yahoo-t5.svg'
import office3 from './images/office365.svg'
import 'moment/min/locales.min'

import locale from '../../localization/locale.json'

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window
  return {
    width,
    height
  }
}
const ValidatingUUIDAndPhoneNumber = (value) => {
  // eslint-disable-next-line react/destructuring-assignment
  const check = value.match(
    '^[0-9a-fA-F]{8}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{4}-[0-9a-fA-F]{12}$'
  )
  if (check) {
    return value
  }

  return `+${value}`
}

const localization = new LocalizedStrings({
  ...JSON.parse(JSON.stringify(locale))
})

// eslint-disable-next-line no-underscore-dangle
moment.locale(localization._interfaceLanguage)
const ListLogo = [
  {
    img: apple,
    name: 'Apple',
    key: 'iCS'
  },
  {
    img: google,
    name: 'Google',
    key: 'Google'
  },
  {
    img: outlook,
    name: 'Outlook',
    key: 'Outlook'
  },
  {
    img: office3,
    name: 'Office365',
    key: 'Office365'
  },
  {
    img: yahoo,
    name: 'Yahoo',
    key: 'Yahoo'
  },
  {
    img: symbol,
    name: 'iCS',
    key: 'iCS'
  }
]
const openInNewTab = (url) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

const sortEventUsers = (a, b) => {
  if (a.user?.avatar === undefined && b.user?.avatar) return 1
  if (a.user?.avatar && b.user?.avatar === undefined) {
    return -1
  }
  return 0
}

const Invitation = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  )
  const [doRedirect, setDoRedirect] = useState(false)
  const [showList, setShowList] = useState(false)
  const [eventInfo, setEventInfo] = useState({
    date: '',
    startDate: '',
    endDate: '',
    ownerName: '',
    title: '',
    users: []
  })
  const { id } = useParams()
  const { search } = useLocation()
  const [isloading, setIsLoading] = useState(true)
  const [deviceInfo, setDeviceInfo] = useState('unkhown')
  const [calendarInfo, setCalendarInfo] = useState({
    google: '',
    outlook: '',
    yahoo: '',
    ics: '',
    apple: ''
  })

  const [showOption, setShowOption] = useState(false)
  function getMobileOperatingSystem() {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera

    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone'
    }

    if (/android/i.test(userAgent)) {
      return 'Android'
    }

    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      return 'iOS'
    }

    return 'unknown'
  }
  function insert(str, index, value) {
    return str.substr(0, index) + value + str.substr(index)
  }

  function addReminders(reminders) {
    let value = ''
    for (let i = 0; i < reminders.length; ) {
      const val = reminders[i].split(' ')

      if (val[1] === 'day' || val[1] === 'days') {
        const minutes = parseInt(val[0], 10) * 24 * 60
        value += `%0ABEGIN:VALARM%0AACTION:DISPLAY%0ADESCRIPTION:REMINDER%0ATRIGGER:-PT${minutes}M%0AEND:VALARM`
      } else if (val[1] === 'week' || val[1] === 'weeks') {
        const minutes = parseInt(val[0], 10) * 7 * 24 * 60
        value += `%0ABEGIN:VALARM%0AACTION:DISPLAY%0ADESCRIPTION:REMINDER%0ATRIGGER:-PT${minutes}M%0AEND:VALARM`
      } else if (val[1] === 'minutes' || val[1] === 'minutes') {
        value += `%0ABEGIN:VALARM%0AACTION:DISPLAY%0ADESCRIPTION:REMINDER%0ATRIGGER:-PT${val[0]}M%0AEND:VALARM`
      }

      i += 1
    }

    return value
  }
  function insertString(a, b, at) {
    const position = a.indexOf(at)

    if (position !== -1) {
      return a.substr(0, position) + b + a.substr(position)
    }

    return ''
  }
  const GetEvent = async (value) => {
    await axios
      .post(`${SHOW_EVENT_PUBLIC_API_URL}/${id}`, {
        phoneNumber: value
      })
      .then((result) => {
        const response = result?.data
        if (response?.event?.createdAt) {
          const newUsers = response?.event.users
            ? response?.event.users.sort(sortEventUsers)
            : []
          setEventInfo({
            date: response?.event.createdAt,
            startDate: response?.event.startDate,
            endDate: response?.event.endDate,
            ownerName: response?.event.owner.name,
            title: response?.event.title,
            users: newUsers,
            status: response?.invitation.status
          })
          const event = makeUrl({
            name: response?.event.title ? response?.event.title : ' ',
            location: response?.event.location ? response?.event.location : ' ',
            details: response?.event.description
              ? `${response?.event.description}-Event Invite:${window?.location?.href}`
              : `Event Invite: ${window?.location?.href}`,
            startsAt: response?.event.startDate
              ? response?.event.startDate
              : ' ',
            endsAt: response?.event.endDate ? response?.event.endDate : ' '
          })
          const eventoutllook = {
            title: response?.event.title ? response?.event.title : ' ',
            location: response?.event.location ? response?.event.location : ' ',
            description: response?.event.description
              ? `${response?.event.description}-Event Invite:${window?.location?.href}`
              : `Event Invite: ${window?.location?.href}`,
            start: response?.event.startDate,
            // ? moment(response?.event.startDate).format('YYYY-MM-DD hh:mm:ss')
            // : ' ',
            end: response?.event.endDate
            // ? moment(response?.event.startDate).format('YYYY-MM-DD hh:mm:ss')
            // : ' '
          }
          const outlookevent = OutLookLink(eventoutllook)
          const office = office365(eventoutllook)
          const icsresult = insertString(
            event.ics,
            `%0AUID:${id}%0ASEQUENCE:${moment(response.event.updatedAt).format(
              'x'
            )}`,
            '%0AURL:'
          )
          if (event.google) {
            setCalendarInfo({
              google: event.google,
              yahoo: event.yahoo,
              outlook: outlookevent,
              apple: event.ics,
              office365: office,
              ics:
                response.event?.reminders?.length !== 0
                  ? insert(
                      icsresult,
                      icsresult.indexOf('%0AEND'),
                      addReminders(response?.event?.reminders).concat(
                        response.event.recurrenceRule
                          ? `%0ARRULE:FREQ=${response?.event?.recurrence?.toUpperCase()};INTERVAL=${
                              response?.event?.recurrenceRule?.interval
                            };BYMONTH=2;BYMONTHDAY=12`
                          : ''
                      )
                    )
                  : icsresult.concat(
                      response.event.recurrenceRule
                        ? `%0ARRULE:FREQ=${response?.event?.recurrence?.toUpperCase()};INTERVAL=${
                            response?.event?.recurrenceRule?.interval
                          };BYMONTH=2;BYMONTHDAY=12`
                        : ''
                    )
            })
          }
          setIsLoading(false)
        } else {
          alert(localization?.InviteNotFound)
        }
      })
      .catch((error) => {
        const msg = error?.response?.data?.message
        if (msg === 'Not Found') {
          alert(localization?.InviteNotFound)
        } else if (msg === 'Event expired') {
          alert(localization?.InviteExpired)
        } else if (msg === 'DECLINE_BY_OWNER') {
          alert(localization?.InviteDeclinedByOwner)
        } else {
          alert(localization?.InviteGenericError)
        }
        setDoRedirect(true)
      })
  }
  useEffect(() => {
    const Value = ValidatingUUIDAndPhoneNumber(`${search.split('p=').pop()}`)
    GetEvent(Value)

    function handleResize() {
      setWindowDimensions(getWindowDimensions())
    }
    const device = getMobileOperatingSystem()
    setDeviceInfo(device.toString())
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])
  const handleClickAway = () => {
    setShowOption(false)
    setShowList(false)
  }
  const InvitationHandler = async (msg) => {
    if (msg === 'accept') {
      const newUsers = eventInfo?.users?.sort(sortEventUsers)
      setEventInfo({
        ...eventInfo,
        users: newUsers,
        status: 'accepted'
      })
    }
    if (msg === 'decline') {
      const newUsers = eventInfo?.users?.sort(sortEventUsers)
      setEventInfo({
        ...eventInfo,
        users: newUsers,
        status: 'declined'
      })
    }

    try {
      const Value = ValidatingUUIDAndPhoneNumber(`${search.split('p=').pop()}`)
      await axios.put(
        `${UPDATE_EVENT_PUBLIC_API_URL}/${id}/invitations/${msg}`,
        { phoneNumber: Value }
      )
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log(error, 'InvitationHandler')
      // eslint-disable-next-line no-alert
      // alert(localization?.InviteGenericError)
      alert(error)
      setDoRedirect(true)
    }
  }

  useEffect(() => {
    const device = getMobileOperatingSystem()
    setDeviceInfo(device.toString())
  }, [windowDimensions])
  if (doRedirect) {
    return <Redirect to="/" />
  }
  if (isloading) {
    return (
      <div className="loaderParent">
        <div className="loader" />
        <p style={{ margin: 'auto', marginTop: '20px' }}>
          {' '}
          {localization?.SpinnerText}
        </p>
      </div>
    )
  }

  const getForImage = (value) => {
    return (
      <>
        <img
          className={`${value.status === 'declined' && 'forImage'} ${
            value.status === 'pending' && 'forImagepending'
          } ${value.status === 'accepted' && 'forImageaccepted'}`}
          src={value.user.avatar ? value.user.avatar : NoImage}
          alt={value.status}
        />
        {(value.status === 'accepted' || value.status === 'declined') && (
          <img
            className="badgeFor"
            src={value.status === 'accepted' ? Yes : No}
            alt={`${value.status} badge`}
          />
        )}
      </>
    )
  }
  return (
    <div className="container">
      <div>
        {windowDimensions.width < 600 ? (
          <img className="logo1" src={logo2} alt="soplanlogo" />
        ) : (
          <img className="logo1" src={logo2} alt="soplanlogo" />
        )}
      </div>
      <div className="row2">
        <div className="calendarDate">
          <p className="month">
            {eventInfo.startDate && moment(eventInfo?.startDate).format('MMM')}
          </p>
          <p className="date">
            {eventInfo.startDate && moment(eventInfo?.startDate).format('D')}
          </p>
          <span className="badge">1</span>
        </div>
        <div className="afterDateText">
          <p className="insideAfterDateText">
            {eventInfo.ownerName && eventInfo.ownerName}{' '}
            {localization?.InviteText}
          </p>
          <p className="insideAfterDateText">
            '{eventInfo.title && eventInfo.title}'
          </p>
        </div>
      </div>
      <div className="row3">
        <p className="row3Text">
          <span className="spectext"> {localization?.InviteWhen} </span>
          {eventInfo.startDate && moment(eventInfo.startDate).format('LLLL')}
        </p>
      </div>
      {showList === false && (
        <div className="row3">
          <div className="row3Text">
            <div className="rowSpecImage">
              <span style={{ marginRight: '8px' }}>
                {localization?.InvitePeople}
              </span>
              {eventInfo.users.map((value, key) => {
                const usersToShow =
                  eventInfo.users.length > 1 && eventInfo.users.length < 5
                    ? eventInfo.users.length - 1
                    : 1
                const newUsersToShow =
                  eventInfo.users.length > 4
                    ? eventInfo.users.length - usersToShow
                    : usersToShow
                const finalUsers =
                  usersToShow === 1 && eventInfo.users.length > 4
                    ? 3
                    : newUsersToShow
                if (key < finalUsers) {
                  return (
                    <div key={key} className="forImageUsername">
                      <div>{getForImage(value)}</div>
                      {eventInfo.users.length === 1 && (
                        <span className="forUserName">{value.user.name}</span>
                      )}
                    </div>
                  )
                }
                if (key === finalUsers)
                  return (
                    <div
                      key={key}
                      onClick={() => {
                        setShowList(!showList)
                      }}
                      onKeyPress={() => {
                        setShowList(!showList)
                      }}
                    >
                      <span className="forExtra" key={key}>
                        +{eventInfo.users.length - finalUsers}
                      </span>
                    </div>
                  )
                return null
              })}
            </div>
          </div>
        </div>
      )}
      {showList && (
        <div className="forListRow">
          <span className="forPeopleList">{localization?.InvitePeople}</span>
          <div className="forListMidDiv">
            {eventInfo.users.map((value, key) => {
              return (
                <div className="forListDiv" key={key}>
                  <div>
                    {value.status === 'pending' && (
                      <img
                        className="forImagepending"
                        src={value.user.avatar ? value.user.avatar : NoImage}
                        alt={key}
                      />
                    )}
                    {value.status === 'accepted' && (
                      <>
                        <img
                          className="forImageaccepted"
                          src={value.user.avatar ? value.user.avatar : NoImage}
                          alt={key}
                        />

                        <img className="badgeFor" src={Yes} alt={key} />
                      </>
                    )}
                    {value.status === 'declined' && (
                      <>
                        <img
                          className="forImage"
                          src={value.user.avatar ? value.user.avatar : NoImage}
                          alt={key}
                        />

                        <img className="badgeFor" src={No} alt={key} />
                      </>
                    )}
                  </div>{' '}
                  <span className="forUserName">{value.user.name}</span>
                </div>
              )
            })}
          </div>
        </div>
      )}
      <div className="row4">
        <button
          className={`${'actionButton'} ${
            eventInfo.status === 'accepted' && 'accepted'
          }`}
          style={{ marginRight: '9px' }}
          type="button"
          onClick={() => {
            if (
              eventInfo.status === 'pending' ||
              eventInfo.status === 'declined'
            ) {
              InvitationHandler('accept')
            }
          }}
        >
          <img
            src={accept}
            alt="acceptlogo"
            className={` acceptLogo ${
              localization?.InviteAccepted.length > 8 ? 'font14Ca' : ''
            }`}
          />

          <span
            className={`${
              localization?.InviteAccepted.length > 8 ? 'font14' : ''
            } ${eventInfo.status === 'accepted' && 'fontAc'}`}
          >
            {eventInfo.status === 'accepted'
              ? `${localization?.InviteAccepted}`
              : `${localization?.InviteAccept}`}
          </span>
        </button>
        <button
          className={`${'actionButton'} ${
            eventInfo.status === 'declined' && 'rejected'
          }`}
          onClick={() => {
            if (
              eventInfo?.status === 'pending' ||
              eventInfo?.status === 'accepted'
            ) {
              InvitationHandler('decline')
            }
          }}
          type="button"
        >
          <img
            src={decline}
            alt="declinelogo"
            className={` declineLogo ${
              localization?.InviteAccepted.length > 8 ? 'font14Ca' : ''
            }`}
          />
          <span
            className={`${
              localization?.InviteAccepted.length > 8 ? 'font14' : ''
            } ${eventInfo.status === 'declined' && 'fontAc'}`}
          >
            {eventInfo?.status === 'declined'
              ? `${localization?.InviteDeclined}`
              : `${localization?.InviteDecline}`}
          </span>
        </button>
      </div>
      <div className="row4">
        <ClickAwayListener
          onClickAway={handleClickAway}
          touchEvent={handleClickAway}
        >
          <button
            type="button"
            className="actionButtonForCalendar"
            onClick={() => {
              setShowOption(!showOption)
            }}
          >
            <img
              style={{ marginRight: '5px' }}
              src={symbol}
              alt="declinelogo"
            />
            <span
              className={`${
                localization?.InviteAccepted.length > 8 ? 'font14Ca' : ''
              }`}
            >
              {localization?.InviteAddToCalendar}
            </span>
          </button>
        </ClickAwayListener>
      </div>
      {showOption && (
        <div
          className={` ${
            deviceInfo === 'iOS' || deviceInfo === 'Android'
              ? 'forCalendarDivMo'
              : 'forCalendarDiv'
          }`}
        >
          {ListLogo.map((value, key) => {
            return (
              // eslint-disable-next-line jsx-a11y/click-events-have-key-events
              <>
                {(value.name === 'Outlook' &&
                  (deviceInfo === 'Android' || deviceInfo === 'iOS')) ||
                (value.name === 'Office365' &&
                  (deviceInfo === 'Android' || deviceInfo === 'iOS')) ? null : (
                  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
                  <div
                    className="forCalendarList"
                    key={key}
                    onClick={() => {
                      if (value.key === 'iCS') {
                        window.location.href =
                          calendarInfo[value.key.toLowerCase()]
                      } else {
                        openInNewTab(calendarInfo[value.key.toLowerCase()])
                      }
                    }}
                  >
                    <img
                      className="forCalendarImg"
                      src={value.img}
                      alt={value.name}
                    />
                    <span style={{ marginLeft: '16px' }}>{value.name}</span>
                  </div>
                )}
              </>
            )
          })}
        </div>
      )}
      <div className="row4">
        <p className="lastText">{localization?.InviteBottomText}</p>
      </div>
      <div className="row5">
        {deviceInfo === 'Android' && (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <img
            className="appstoreImg"
            src={googleStore}
            alt=""
            onKeyPress={() => {
              openInNewTab(
                'https://play.google.com/store/apps/details?id=com.soplan'
              )
            }}
            onClick={() => {
              openInNewTab(
                'https://play.google.com/store/apps/details?id=com.soplan'
              )
            }}
          />
        )}
        {deviceInfo === 'iOS' && (
          // eslint-disable-next-line jsx-a11y/no-noninteractive-element-interactions
          <img
            className="appstoreImg"
            src={appleStore}
            alt=""
            onKeyPress={() => {
              openInNewTab('https://apps.apple.com/us/app/soplan/id1521063648')
            }}
            onClick={() => {
              openInNewTab('https://apps.apple.com/us/app/soplan/id1521063648')
            }}
          />
        )}

        {deviceInfo === 'unknown' && (
          <div>
            <img
              className="appstoreImg"
              src={googleStore}
              alt=""
              onKeyPress={() => {
                openInNewTab(
                  'https://play.google.com/store/apps/details?id=com.soplan'
                )
              }}
              onClick={() => {
                openInNewTab(
                  'https://play.google.com/store/apps/details?id=com.soplan'
                )
              }}
            />

            <img
              className="appstoreImg"
              src={appleStore}
              alt=""
              onKeyPress={() => {
                openInNewTab(
                  'https://apps.apple.com/us/app/soplan/id1521063648'
                )
              }}
              onClick={() => {
                openInNewTab(
                  'https://apps.apple.com/us/app/soplan/id1521063648'
                )
              }}
            />
          </div>
        )}
      </div>
    </div>
  )
}

export default Invitation
