import React, { useMemo } from 'react'
// react-router-dom
import { Link, useLocation } from 'react-router-dom'
// assets
import mainLogo from 'assets/logo/main-logo.svg'
import GPDownload from 'assets/logo/google-play-download.svg'
import APDownload from 'assets/logo/app-store-download.svg'
import facebook from 'assets/logo/facebook.svg'
import twitter from 'assets/logo/twitter.svg'
import linkedIn from 'assets/logo/linked-in.svg'
// css
import classes from 'components/Footer/Footer.module.css'
import DownloadButton from 'components/DownloadButton'

const Footer = () => {
  const fullYear = useMemo(() => new Date().getFullYear(), [])
  const location = useLocation()
  if (location.pathname.charAt(1) === 'i') {
    return <></>
  }
  return (
    <footer className={classes.wrapper}>
      <div className={classes.container}>
        <div className={classes.section}>
          <div className={classes.logo}>
            <Link to="/" className={classes.logo}>
              <img src={mainLogo} alt="Main logo" />
            </Link>
          </div>

          <div className={classes.buttons}>
            <DownloadButton
              imgSrc={GPDownload}
              href="https://play.google.com/store/apps/details?id=com.soplan"
              alt="Download from google play"
            />

            <DownloadButton
              imgSrc={APDownload}
              href="https://apps.apple.com/us/app/soplan/id1521063648"
              alt="Download from app play"
            />
          </div>

          <div className={classes.terms}>
            <div>© {fullYear} SoPlan. All right reserved</div>

            <div className={classes.links}>
              <Link className={classes.link} to="/privacy">
                Privacy Policy
              </Link>
              <Link className={classes.link} to="/terms">
                Terms of service
              </Link>
            </div>
          </div>
        </div>

        <div className={classes.section}>
          <h3 className={classes.navTitle}>SoPlan</h3>

          <Link className={classes.navItem} to="/features">
            Features
          </Link>

          <Link className={classes.navItem} to="/security">
            Security
          </Link>

          <Link className={classes.navItem} to="/business">
            Business
          </Link>
        </div>

        <div className={classes.section}>
          <h3 className={classes.navTitle}>Company</h3>

          <Link className={classes.navItem} to="/about">
            About
          </Link>

          <Link className={classes.navItem} to="/contact">
            Contact
          </Link>
        </div>

        <div className={classes.section}>
          <h3 className={classes.navTitle}>Help</h3>
          <Link className={classes.navItem} to="/vulnerability-disclosure">
            Vulnerability disclosure
          </Link>

          {/* <Link className={classes.navItem} to="/faq">
            FAQ
          </Link> */}
        </div>

        <div className={classes.section}>
          <h3 className={classes.navTitle}>Social Media</h3>

          <div className={classes.socialLinkList}>
            <a
              className={classes.socialLink}
              href="https://www.linkedin.com/company/soplan"
            >
              <img src={linkedIn} alt="LinkedIn" />
            </a>

            <a
              className={classes.socialLink}
              href="https://twitter.com/SoPlanApp"
            >
              <img src={twitter} alt="Twitter" />
            </a>

            <a
              className={classes.socialLink}
              href="https://www.facebook.com/SoPlanApp"
            >
              <img src={facebook} alt="Facebook" />
            </a>
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
