import React from 'react'
// react-router-dom
import {
  BrowserRouter as Router,
  Redirect,
  Route,
  Switch
} from 'react-router-dom'
// components
import Header from 'components/Header'
import Footer from 'components/Footer'
import ScrollToTop from 'components/ScrollToTop'
// pages
import Main from 'pages/Main'
import Features from 'pages/Features'
import Business from 'pages/Business'
import Security from 'pages/Security'
// import Faq from 'pages/Faq'
import About from 'pages/About'
import Contact from 'pages/Contact'
import VulnerabilityDisclosure from 'pages/VulnerabilityDisclosure'
import PrivacyPolicy from 'pages/PrivacyPolicy'
import TermsOfService from 'pages/TermsOfService'
import Invitation from 'pages/Invitation'

const App = () => {
  return (
    <div className="app">
      <Router>
        <ScrollToTop />
        <Header />
        <Switch>
          <Route exact path="/" component={Main} />
          <Route exact path="/features" component={Features} />
          <Route exact path="/business" component={Business} />
          <Route exact path="/security" component={Security} />
          {/* <Route exact path="/faq" component={Faq} /> */}
          <Route exact path="/about" component={About} />
          <Route exact path="/contact" component={Contact} />
          <Route
            exact
            path="/vulnerability-disclosure"
            component={VulnerabilityDisclosure}
          />
          <Route exact path="/privacy" component={PrivacyPolicy} />
          <Route exact path="/terms" component={TermsOfService} />
          <Route exact path="/i=:id" component={Invitation} />

          <Redirect to="/" />
        </Switch>
        <Footer />
      </Router>
    </div>
  )
}

export default App
