// Production account
// export const SUBSCRIBE_POST_API_URL = `${process.env.REACT_APP_API_URL}/landing/api/subscribe`

// export const CONTACT_POST_API_URL = `${process.env.REACT_APP_API_URL}/landing/api/contact-us`

// export const SHOW_EVENT_PUBLIC_API_URL = `${process.env.REACT_APP_API_URL}/app/api/public/event`

// export const UPDATE_EVENT_PUBLIC_API_URL = `${process.env.REACT_APP_API_URL}/app/api/public/events`

// Staging account 
export const SUBSCRIBE_POST_API_URL = `${process.env.REACT_APP_API_URL}/api/subscribe`

export const CONTACT_POST_API_URL = `${process.env.REACT_APP_API_URL}/api/contact-us`

export const SHOW_EVENT_PUBLIC_API_URL = `${process.env.REACT_APP_API_URL}/api/public/event`

export const UPDATE_EVENT_PUBLIC_API_URL = `${process.env.REACT_APP_API_URL}/api/public/events`
